import React, { useEffect, useState } from "react";
import babbo from "./assets/Babbo.mp4";
import { motion } from "framer-motion";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import {db} from "./firebase";

function Video() {
    const [end, setEnd] = useState(false);
    const handleVideoEnd = () => {
        setEnd(true);
    };

    useEffect(() => {
      //getMedia({ video: true });
      counter();
  }, []);

  const counter = async () => {
      const oggi = new Date();
      const str =
          "" +
          oggi.getFullYear() +
          "" +
          (oggi.getMonth() + 1) +
          "" +
          oggi.getDate() +
          "";
         
          const docRef = doc(db, "portanuovavideo", str);
          const docSnap = await getDoc(docRef);
          var num = 0;
          if (docSnap.exists()) {
              num = docSnap.data().num;
              num++;
              await updateDoc(docRef, { num: num });
          } else {
              await setDoc(doc(db, "portanuovavideo", str), { num: 1 });
          }
      
  };

    return (
        
        <div className="h-[100vh] object-contain flex bg-[#257d6f] justify-center">
            {end ? (
                <div className="bg-[#257d6f] w-full min-h-[200px] flex items-center justify-center">
                   <AIButtonWrapper />
                </div>
            ) : (
                <video
                    height={"100%"}
                    className="h-full"
                    autoPlay
                    controls
                    playsInline
                    onEnded={handleVideoEnd}
                >
                    <source src={babbo} type="video/mp4" />
                </video>
            )}
        </div>
    );
}

export default Video;

//https://portanuova.page.link/christmas



const AIButtonWrapper = () => {
  return (
    <div className="flex flex-col items-center gap-24">
      <img src={require("./assets/enjoy.png")} className="md:w-[50%] w-[70%]" alt="" />
        <img src="https://www.portanuova.com/assets/img/icon-portanuova-app.svg" width={"35%"} alt="" />
      <AIButton />
    </div>
  );
};

const AIButton = () => {
  return (
    <button onClick={()=>window.location.href = "https://portanuova.page.link/christmas"} className="text-white font-semibold px-20 py-4 rounded-md overflow-hidden relative transition-transform hover:scale-105 active:scale-95">
      <span className="relative font-gellix text-2xl z-10">Vai all'App</span>
      <motion.div
        initial={{ left: 0 }}
        animate={{ left: "-300%" }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 4,
          ease: "linear",
        }}
        className="bg-black absolute z-0 inset-0 w-[400%]"
      ></motion.div>
    </button>
  );
};
