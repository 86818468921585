// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzzCptXHkME9VOaTXD3E2sziDoYJWZ8og",
  authDomain: "qrcode-apeiron.firebaseapp.com",
  projectId: "qrcode-apeiron",
  storageBucket: "qrcode-apeiron.appspot.com",
  messagingSenderId: "1020282049351",
  appId: "1:1020282049351:web:99d2099998933f9e8e3262"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);