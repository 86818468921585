import React, { useEffect, useRef, useState } from "react";
import {
    gsap,
    ScrollTrigger,
    Draggable,
    MotionPathPlugin,
    Power4,
    Power1,
} from "gsap/all";
import Body from "./Body";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { scrollTo } from "./utils/scrollTo";
import Home from "./Home";
import { BrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import Video from "./Video";

function App() {
    const [loaded, setLoaded] = useState(false);
    let cloud1ref = useRef(null);
    const [home, setHome] = useState(false);
    gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin);

    useEffect(() => {
        gsap.set("#logo_A", { scale: 0.38 });
        if (window.innerWidth > 640) {
            gsap.to("#logo_A", {
                scale: 320,
                y: -4000,
                ease: "easeInOut",
                duration: 10,
                delay: 10,
                rotate: 1300,
                scrollTrigger: {
                    trigger: "#app",
                    start: "20px",
                    end: "bottom",
                    scrub: 2,
                },
            });
        } else {
            gsap.to("#logo_A", {
                x: 1000,
                ease: "easeInOut",
                duration: 10,
                delay: 10,
                scrollTrigger: {
                    trigger: "#logo_container",
                    start: "top",
                    end: "bottom",
                    scrub: 2,
                },
            });
        }
        gsap.to("#peiron", {
            x: 1000,
            ease: "easeInOut",
            duration: 10,
            delay: 10,
            scrollTrigger: {
                trigger: "#logo_container",
                start: "top",
                end: "200vh",
                scrub: 2,
            },
        });

        window.addEventListener("scroll", (e) => {
            const offset = window.pageYOffset;
            //console.log(offset);
            if (offset > 840) {
                setHome(true);
            } else {
                setHome(false);
            }
        });
    }, []);

    return (
        <div id="app" className="overflow-x-hidden">
            <Navbar colore={home ? "bianco" : "nero"} />
            <BrowserRouter>
                <Routes>
                    <Route
                        path="*"
                        element={
                            <Home
                                home={home}
                                cloud1ref={cloud1ref}
                                scrollTo={scrollTo}
                            />
                        }
                    />
					<Route path="/portanuova" element={<Video />} />
                </Routes>
            </BrowserRouter>

            <Footer />
        </div>
    );
}

export default App;

/*
<div className="relative h-[300vh] hidden">
				<div className="bg-white w-full h-[100vh]"></div>
				<img
					id="mountBG"
					className="absolute top-0 w-full"
					src={require(`./assets/${photos[3]}`)}
					alt={"index"}
					ref={cloud1ref}
				/>
				<img
					id="montagna2"
					className="absolute top-0 w-full"
					src={require(`./assets/${photos[2]}`)}
					alt={"index"}
					ref={cloud1ref}
				/>
				<img
					id="montagna"
					className="absolute top-0 w-full"
					src={require(`./assets/${photos[1]}`)}
					alt={"index"}
				/>
			</div>

*/
