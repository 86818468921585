import React from "react";
import { scrollTo } from "./utils/scrollTo";
import logo from "./assets/logo.svg"
import {useParams} from "react-router-dom"

function Navbar(colore) {
	const slug = window.location.pathname;
	console.log(slug);
	return (
		<div className={`fixed z-[999] top-4 w-full  flex justify-between ${window.innerWidth < 640 && 'hidden'}`}>
			<div
				className="flex justify-center items-center ml-5 cursor-pointer"
				onClick={() => {
					slug === '/portanuova' && (window.location.href = "/");
					scrollTo({ id: "app", duration: 1450 });
				}}
			>
				<img className="transition duration-500 hover:rotate-180" width={"100px"} height={'100px'} src={logo} alt="" />
			</div>
			<div className="hidden flex mr-7 gap-2 text-lg font-medium cursor-pointer">
				<p>It</p>
				<p>|</p>
				<p>En</p>
			</div>
		</div>
	);
}

export default Navbar;
