import React from 'react'
import Body from './Body';

function Home({home, cloud1ref, scrollTo}) {
  return (
    <div>
        <div
				id="logo_container"
				className={`bg-white h-[60vh] ${home ? "opacity-0" : "opacity-100"} `}
			>
				<div className="flex items-center justify-center fixed text-[50px] md:text-[150px] font-bold top-[40%] left-[50%] text-center translate-x-[-60%] md:translate-x-[-50%] translate-y-[-50%]">
					
					<p id="logo_A" ref={cloud1ref} className='font-alt1 text-[150px] md:text-[400px] relative left-10 md:left-0' >
						A
					</p>
					<p id="peiron" className="font-alt1 justify-self-start md:translate-x-[-15%]">
						PEIRON
					</p>
					
				</div>
				<div className="fixed w-full top-[90%] left-[50%] text-black translate-x-[-50%]">
					
					<div 
					className="arrow-down cursor-pointer"
					onClick={() => {scrollTo({ id: "body", duration: 1450 });}}>
						<div className="left"></div>
						<div className="right"></div>
					</div>
				</div>
			</div>
			<div id="body"
				className={`bg-black  ${!home ? "opacity-0" : "opacity-100"}
				
				${window.innerWidth < 640 && "transition-opacity duration-500"}`}
			>
				<Body />
			</div>
    </div>
  )
}

export default Home