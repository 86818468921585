import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ImgBtn from "./ImgBtn";
import Lavoro from "./Lavoro";
import ScrollContainer from "react-indiana-drag-scroll";
import { useTranslation } from "react-i18next";

function Body() {
    gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin);
    const { t } = useTranslation();

    useEffect(() => {
        gsap.to("#I", {
            scaleY: 130,
            y: 2200,
            duration: 10,
            delay: 10,
            ease: "easeInOut",
            scrollTrigger: {
                trigger: "#app",
                start: "940px",
                end: "bottom ",
                scrub: 3,
            },
        });
        if (window.innerWidth > 640) {
            gsap.to("#point1", {
                scale: 1.6,
                duration: 10,
                ease: "easeInOut",
                delay: 2,
                scrollTrigger: {
                    trigger: "#point1",
                    start: "-150% center ",
                    end: "50% center",
                    scrub: 3,
                },
            });
            gsap.to("#point2", {
                scale: 1.6,
                duration: 10,
                ease: "easeInOut",
                delay: 2,
                scrollTrigger: {
                    trigger: "#point2",
                    start: "-150% center ",
                    end: "50% center",
                    scrub: 3,
                },
            });
            gsap.to("#point3", {
                scale: 1.6,
                duration: 10,
                ease: "easeInOut",
                delay: 2,
                scrollTrigger: {
                    trigger: "#point3",
                    start: "-150% center ",
                    end: "50% center",
                    scrub: 3,
                },
            });
        } else {
            gsap.to("#point1", {
                scale: 1,
                duration: 10,
                ease: "easeInOut",
                delay: 2,
                scrollTrigger: {
                    trigger: "#point1",
                    start: "-150% center ",
                    end: "50% center",
                    scrub: 3,
                },
            });
            gsap.to("#point2", {
                scale: 1,
                duration: 10,
                ease: "easeInOut",
                delay: 2,
                scrollTrigger: {
                    trigger: "#point2",
                    start: "-150% center ",
                    end: "50% center",
                    scrub: 3,
                },
            });
            gsap.to("#point3", {
                scale: 1,
                duration: 10,
                ease: "easeInOut",
                delay: 2,
                scrollTrigger: {
                    trigger: "#point3",
                    start: "-150% center ",
                    end: "50% center",
                    scrub: 3,
                },
            });
        }

        gsap.fromTo(
            "#contact1",
            { x: -2000 },
            {
                x: 0,
                duration: 10,
                ease: "easeInOut",
                delay: 10,
                scrollTrigger: {
                    trigger: "#contact1",
                    start: "-600% center",
                    end: "-500% center",
                    scrub: 3,
                },
            }
        );

        gsap.fromTo(
            "#contact3",
            { x: -2000 },
            {
                x: 0,
                duration: 10,
                ease: "easeInOut",
                delay: 10,
                scrollTrigger: {
                    trigger: "#contact3",
                    start: "-600% center",
                    end: "-500% center",
                    scrub: 3,
                },
            }
        );

        gsap.fromTo(
            "#contact2",
            { x: 2000 },
            {
                x: 0,
                duration: 10,
                ease: "easeInOut",
                delay: 10,
                scrollTrigger: {
                    trigger: "#contact2",
                    start: "-600% center",
                    end: "-500% center",
                    scrub: 3,
                },
            }
        );
        gsap.fromTo(
            "#contact4",
            { x: 2000 },
            {
                x: 0,
                duration: 10,
                ease: "easeInOut",
                delay: 10,
                scrollTrigger: {
                    trigger: "#contact4",
                    start: "-600% center",
                    end: "-500% center",
                    scrub: 3,
                },
            }
        );
        gsap.fromTo(
            "#invia",
            { scale: 0.2 },
            {
                scale: 1,
                duration: 10,
                ease: "easeInOut",
                delay: 10,
                rotate: 360,
                scrollTrigger: {
                    trigger: "#invia",
                    start: "-1500% center",
                    end: "-1400% center",
                    scrub: 3,
                },
            }
        );
        gsap.fromTo(
            "#lavori",
            { scale: 0.2 },
            {
                scale: 1,
                duration: 10,
                ease: "easeInOut",
                delay: 2,
                scrollTrigger: {
                    trigger: "#lavori",
                    start: "-700% center",
                    end: "-500% center",
                    scrub: 3,
                },
            }
        );
        gsap.fromTo(
            "#lavori2",
            { scale: 0.2 },
            {
                scale: 1,
                duration: 10,
                ease: "easeInOut",
                delay: 2,
                scrollTrigger: {
                    trigger: "#lavori2",
                    start: "-700% center",
                    end: "-500% center",
                    scrub: 3,
                },
            }
        );
    }, []);

    const [data, setData] = useState({
        name: "",
        cognome: "",
        telefono: "",
        mail: "",
        msg: "",
        page: "APEIRON Contact",
        type: "APEIRON",
    });

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };

    const sendMail = async () => {
        const headers = {
            "Access-Control-Allow-Origin": "*",
        };

        axios
            .post("https://webinal.web.app/api/mail/apeiron", data, { headers })
            .then((res) => {
                console.log(res.data);
                const link = String(res.data._id).toLowerCase();
                window.location.href = "aftermail/" + link.toLowerCase();
            });
    };

    return (
        <div
            id="divI"
            className="text-white pt-96 Xh-[600vh] Xmd:h-[6300px] w-full"
        >
            <div
                id="triggerI"
                className="text-center font-alt1 h-32 text-6xl fixed top-10 left-[50%] translate-x-[-50%]"
            >
                <div className="flex justify-center">
                    <p className="font-alt1">APE</p>
                    <p id="I" className="font-alt1 z-20 scale-100">
                        I
                    </p>
                    <p className="font-alt1">RON</p>
                </div>
            </div>

            <div className="non-selezionabile flex w-full mt-[100vh] mb-[50vh] md:mb-[100vh] p-82 justify-center">
                <div id="point1" className=" scale-50 relative flex">
                    <img
                        className="w-96 grayscale object-cover brightness-[27%]  aspect-square rounded-full "
                        src={require("./assets/progettazione2.jpeg")}
                        alt=""
                    />
                    <div className="absolute flex flex-col border-2 border-white rounded-full justify-center items-center w-full h-full">
                        <h1 className="text-xl font-bold mb-5 uppercase">
                            {t("progettazione")}
                        </h1>
                        <p className="font-semibold text-center text-[9px] w-60">
                            Il tuo <b>metaverso</b>: perchè crearlo e cosa può
                            offrire.
                        </p>
                        <p className="font-semibold text-center mt-2 text-[9px] w-60">
                            Vendi i tuoi prodotti o semplicemente esponili in un
                            ambiente unico disegnato <b>PER TE</b>
                        </p>
                    </div>
                </div>
            </div>

            <div className="non-selezionabile flex w-full mb-[50vh] md:mb-[100vh] p-82 justify-center">
                <div id="point2" className="scale-50 relative flex">
                    <img
                        className="w-96 grayscale object-cover  brightness-[55%] aspect-square rounded-full"
                        src={require("./assets/produzione3.jpeg")}
                        alt=""
                    />
                    <div className="absolute flex flex-col border-2 border-white rounded-full justify-center items-center w-full h-full">
                        <h1 className="text-xl font-bold mb-5">PRODUZIONE</h1>
                        <p className="font-semibold text-center mt-2 text-[9px] w-60">
                            Il nostro team di <b>progettazione 3D</b> creerà il
                            layout e il design del tuo spazio virtuale.
                        </p>
                        <p className="font-semibold text-center mt-2 text-[9px] w-60">
                            Definiremo ogni dettaglio di stile, dai pavimenti
                            alle pareti, dagli esterni alle vetrate e agli
                            arredi.
                        </p>
                        <p className="font-semibold text-center mt-2 text-[9px] w-60">
                            Apeiron realizza spazi <b>su misura</b> unici per la
                            tua azienda, i tuoi prodotti e i tuoi clienti.
                        </p>
                        <p className="font-semibold text-center mt-2 text-[9px] w-60">
                            Immagina di creare uno showroom senza limiti di
                            spazio.
                        </p>
                    </div>
                </div>
            </div>
            <div className="non-selezionabile flex w-full mb-[50vh] md:mb-[70vh] p-82 justify-center">
                <div id="point3" className="scale-50 relative flex">
                    <img
                        className="w-96 grayscale object-cover  brightness-[55%] aspect-square rounded-full"
                        src={require("./assets/advertising5.jpeg")}
                        alt=""
                    />
                    <div className="absolute flex flex-col border-2 border-white rounded-full justify-center items-center w-full h-full">
                        <h1 className="text-xl font-bold mb-5">
                            ALTRI PROGETTI
                        </h1>
                        <p className="font-semibold text-center mt-2 text-[9px] w-60">
                            Il nostro viaggio insieme non finisce con il lancio
                            del tuo metaverso, ma prosegue con la creazione di
                            un intero <b>ecosistema</b> intorno ad esso.
                        </p>
                        <p className="font-semibold text-center mt-2 text-[9px] w-60">
                            Poche aziende fanno questo passo e un buon lancio
                            può aiutare a <b>pubblicizzare</b> il tuo brand.
                        </p>
                        <p className="font-semibold text-center mt-2 text-[9px] w-60">
                            Servizio stampa, l’evento fisico, campagne online
                            sono alcuni dei servizi che possiamo offrirti,
                        </p>
                        <p className="font-semibold text-center mt-2 text-[9px] w-60">
                            grazie alla nostra rete di partner attivi nei
                            rispettivi settori.{" "}
                        </p>
                    </div>
                </div>
            </div>

            <div className="font-bold md:w-auto relative w-[100vw] p-2 text-white text-center opacity-100 bg-black lavori_txt rounded-lg transition-all duration-300 z-50">
                <p className="text-3xl">A-MORE In The Metaverse</p>
            </div>
            <div className="font-bold flex items-center justify-center relative mt-10 text-white text-center opacity-100 bg-black lavori_txt rounded-lg transition-all duration-300 z-50">
                <img
                    width={"40px"}
                    className="rotate-180"
                    src={require("./assets/right-arrow.png")}
                    alt=""
                />
                <p className="px-5 bg-black">Trascina</p>
                <img
                    width={"40px"}
                    className={""}
                    src={require("./assets/right-arrow.png")}
                    alt=""
                />
            </div>
            <div className="flex justify-center">
                <div className="font-bold md:w-[50vw] w-[100vw] p-5 pt-10 text-white text-center opacity-100 bg-black lavori_txt rounded-lg transition-all duration-300 z-50">
                    <p>
                        Abbiamo creato un innovativo negozio virtuale nel
                        metaverso per il brand A-MORE.
                        <br /> Lo spazio consente ai clienti di visualizzare i
                        prodotti, vedere in anteprima le prossime collezioni e
                        acquistare direttamente.
                        <br /> Lo store sarà anche uno spazio d'incontro per i
                        membri della community A-MORE.
                        <br /> Per lanciare "A-MORE In The Metaverse" abbiamo
                        collaborato a un evento fisico.
                    </p>
                </div>
            </div>
            <div
                id="lavori"
                className="flex w-full justify-center items-center mt-12 mb-[60px]"
            >
                <ScrollContainer vertical="false">
                    <div className="flex w-[600vw] md:w-[250vw] teamcard cursor-ew-resize  ">
                        <div className=" m-5" draggable={false}>
                            <div className="font-bold hidden flex items-center justify-center mt-10 text-white text-center opacity-100 bg-black lavori_txt rounded-lg transition-all duration-300 z-50">
                                <img
                                    width={"40px"}
                                    className="rotate-180"
                                    src={require("./assets/right-arrow.png")}
                                    alt=""
                                />
                                <p className="px-5">{`Trascina`}</p>
                                <img
                                    width={"40px"}
                                    className={""}
                                    src={require("./assets/right-arrow.png")}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="w-[600px] m-5" draggable={false}>
                            <img
                                width={"100%"}
                                src={require("./assets/0312.png")}
                                alt=""
                            />
                        </div>
                        <div className="w-[600px] m-5" draggable={false}>
                            <img
                                width={"100%"}
                                src={require("./assets/0578.png")}
                                alt=""
                            />
                        </div>
                        <div className="w-[600px] m-5" draggable={false}>
                            <img
                                width={"100%"}
                                src={require("./assets/0784.png")}
                                alt=""
                            />
                        </div>
                        <div className="w-[600px] m-5" draggable={false}>
                            <img
                                width={"100%"}
                                src={require("./assets/0944.png")}
                                alt=""
                            />
                        </div>
                    </div>
                </ScrollContainer>
            </div>
            <div className="flex justify-center mb-[300px]">
                <button
                    onClick={() =>
                        window.open(
                            "https://www.spatial.io/s/A-MORE-metaverse-64010cd23aa2f200e974bad8?share=3107239974888623663",
                            "_blank"
                        )
                    }
                    type="submit"
                    className=" opacity-100 z-50  hover:scale-110  rounded-full px-5 py-2 transition duration-500 bg-white text-black flex justify-center items-center font-semibold"
                >
                    SCOPRI DI PIÚ
                </button>
            </div>

            <div className="font-bold md:w-auto relative w-[100vw] p-2 text-white text-center opacity-100 bg-black lavori_txt rounded-lg transition-all duration-300 z-50">
                <p className="text-3xl">Portanuova Pop Christmas</p>
            </div>
            <div className="font-bold flex items-center justify-center relative mt-10 text-white text-center opacity-100 bg-black lavori_txt rounded-lg transition-all duration-300 z-50">
                <img
                    width={"40px"}
                    className="rotate-180"
                    src={require("./assets/right-arrow.png")}
                    alt=""
                />
                <p className="px-5 bg-black">Trascina</p>
                <img
                    width={"40px"}
                    className={""}
                    src={require("./assets/right-arrow.png")}
                    alt=""
                />
            </div>
            <div className="flex justify-center">
                <div className="font-bold md:w-[50vw] w-[100vw] p-5 pt-10 text-white text-center opacity-100 bg-black lavori_txt rounded-lg transition-all duration-300 z-50">
                    <p>
                        Per il quartiere innovativo “Portanuova” a Milano
                        abbiamo contribuito a rendere digitale e interattiva la
                        loro attivazione natalizia nell’ambito del progetto Pop
                        Christmas powered by Warner Music Italy. Ci siamo
                        occupati della progettazione della produzione dei filtri
                        Instagram AR che completano l’esperienza all’interno
                        delle stanze che compongono l’Albero di Natale di Piazza
                        Gae Aulenti nel 2023. Inoltre abbiamo disegnato e
                        prodotto i codici QR personalizzati per accedere
                        all’esperienza senza minare lo stile natalizio
                        dell’albero.
                    </p>
                </div>
            </div>
            <div
                id="lavori2"
                className="flex w-full justify-center items-center mt-12 mb-[60px]"
            >
                <ScrollContainer vertical="false">
                    <div className="flex w-[600vw] md:w-[250vw] teamcard cursor-ew-resize  ">
                        <div className=" m-5" draggable={false}>
                            <div className="font-bold hidden flex items-center justify-center mt-10 text-white text-center opacity-100 bg-black lavori_txt rounded-lg transition-all duration-300 z-50">
                                <img
                                    width={"40px"}
                                    className="rotate-180"
                                    src={require("./assets/right-arrow.png")}
                                    alt=""
                                />
                                <p className="px-5">{`Trascina`}</p>
                                <img
                                    width={"40px"}
                                    className={""}
                                    src={require("./assets/right-arrow.png")}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="w-[300px] m-5" draggable={false}>
                            <img
							className="object-cover h-full"
                                width={"100%"}
                                src={require("./assets/portanuova/IMG_0069.jpg")}
                                alt=""
                            />
                        </div>
                        <div className="w-[300px] m-5" draggable={false}>
                            <img
							className="object-cover h-full"
                                width={"100%"}
                                src={require("./assets/portanuova/IMG_0070.jpg")}
                                alt=""
                            />
                        </div>
                        <div className="w-[300px] m-5" draggable={false}>
                            <img
							className="object-cover h-full"
                                width={"100%"}
                                src={require("./assets/portanuova/IMG_0071.jpg")}
                                alt=""
                            />
                        </div>
                        <div className="w-[300px] m-5" draggable={false}>
                            <img
							className="object-cover h-full"
                                width={"100%"}
                                src={require("./assets/portanuova/IMG_0072.jpg")}
                                alt=""
                            />
                        </div>
                        <div className="w-[300px] m-5" draggable={false}>
                            <img
							className="object-cover h-full"
                                width={"100%"}
                                src={require("./assets/portanuova/IMG_0073.jpg")}
                                alt=""
                            />
                        </div>
                        <div className="w-[300px] bg-red-500 object-cover m-5" draggable={false}>
                            <img
							className="object-cover h-full"
                                width={"100%"}
                                src={require("./assets/portanuova/IMG_0074.jpg")}
                                alt=""
                            />
                        </div>
                        <div className="w-[300px] m-5" draggable={false}>
                            <img
							className="object-cover h-full"
                                width={"100%"}
                                src={require("./assets/portanuova/IMG_0075.jpg")}
                                alt=""
                            />
                        </div>
                    </div>
                </ScrollContainer>
            </div>
            <div className="flex justify-center mb-[300px]">
                <button
                    onClick={() =>
                        window.open(
                            "https://www.instagram.com/reel/C2hWajrohy8/?igsh=MWphY2cydGZxZTNidw%3D%3D",
                            "_blank"
                        )
                    }
                    type="submit"
                    className=" opacity-100 z-50  hover:scale-110  rounded-full px-5 py-2 transition duration-500 bg-white text-black flex justify-center items-center font-semibold"
                >
                    SCOPRI DI PIÚ
                </button>
            </div>
            <h6 className="text-2xl mb-4 font-bold ml-2 md:ml-[183px]">
                CONTATTI
            </h6>
            <form
                onSubmit={sendMail}
                className="flex flex-col justify-center items-center space-y-10"
            >
                <div className="grid md:grid-cols-2 w-full place-items-center gap-y-10">
                    <div id="contact1" className="relative">
                        <input
                            type="text"
                            name="name"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-96 text-sm text-white bg-transparent border-0 border-b-2 border-b-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                            placeholder=" "
                            required
                            onChange={handleChange}
                            value={data.name}
                        />
                        <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]px-2 peer-focus:px-2 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                            Nome
                        </label>
                    </div>
                    <div id="contact2" className="relative">
                        <input
                            type="text"
                            name="cognome"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-96 text-sm text-white bg-transparent border-0  border-b-2 border-b-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                            placeholder=" "
                            required
                            onChange={handleChange}
                            value={data.cognome}
                        />
                        <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm duration-300 border-0 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]px-2 peer-focus:px-2 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                            Cognome
                        </label>
                    </div>
                    <div id="contact3" className="relative">
                        <input
                            type="text"
                            name="telefono"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-96 text-sm text-white bg-transparent border-0 border-b-2 border-b-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                            placeholder=" "
                            onChange={handleChange}
                            value={data.telefono}
                        />
                        <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]px-2 peer-focus:px-2 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                            Telefono
                        </label>
                    </div>
                    <div id="contact4" className="relative">
                        <input
                            type="text"
                            name="mail"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-96 text-sm text-white bg-transparent border-0 border-b-2 border-b-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                            placeholder=" "
                            required
                            onChange={handleChange}
                            value={data.mail}
                        />
                        <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]px-2 peer-focus:px-2 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                            Email
                        </label>
                    </div>
                </div>
                <div id="contact5" className="relative mt-10 bg-black ">
                    <textarea
                        type="text"
                        name="msg"
                        id="floating_outlined"
                        className="blockpx-2.5 pb-2.5 pt-4 w-[300px] md:w-[700px] text-sm text-white bg-transparent border-0 border-b-2 border-b-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                        placeholder=" "
                        onChange={handleChange}
                        value={data.msg}
                    />
                    <label
                        htmlFor="floating_outlined"
                        className="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]px-2 peer-focus:px-2 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                        Messaggio
                    </label>
                </div>
                <div id="invia">
                    <button
                        type="submit"
                        className="w-32 h-32 z-50 translate-x-[-7px] rounded-full hover:scale-110 transition duration-500 bg-white text-black flex justify-center items-center font-semibold"
                    >
                        INVIA
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Body;

/*

<ImgBtn
						titolo="A-MORE In The Metaverse"
						img="amore.jpg"
						link="https://www.spatial.io/s/A-MORE-metaverse-64010cd23aa2f200e974bad8?share=3107239974888623663"
					/>
*/
