import React from "react";

function Footer() {
	const slug = window.location.pathname;
	console.log(slug);
	return (
		<>
		{
			slug !== '/portanuova' &&
			(
				<div className="relative w-[100vw] mt-12 bg-white text-black md:h-52  border-solid border-white grid grid-cols-12">
			<div className="md:col-span-3 col-span-12  flex items-center justify-center">
				<p className="font-semibold p-4 text-xl font-alt1">APEIRON</p>
			</div>
			<div className="md:col-start-6 md:col-span-3 col-start-2 col-span-12">
				<p className="uppercase cursor-pointer font-semibold pt-8">Contatti</p>
				<p>
					<a href="tel:+393517649360" className="pt-5 cursor-pointer">
						<b className="text-lg font-semibold">T </b>: +39 3517649360
					</a>
				</p>
				<p>
					<a
						href="mailto: info@apeironstudios.net"
						className="pt-2 cursor-pointer"
					>
						<b className="text-lg font-semibold">E </b>:
						info@apeironstudios.net
					</a>
				</p>
				<p>
					<a
						href="https://www.google.com/maps/place/Via+Antonello+da+Messina,+5,+20146+Milano+MI/@45.4665867,9.1396738,17z/data=!3m1!4b1!4m6!3m5!1s0x4786c103b52b7d4d:0x9b740345865a5b63!8m2!3d45.466583!4d9.1422487!16s%2Fg%2F11ss4pcdyr?entry=ttu"
						className="pt-2 cursor-pointer"
						target={'_blank'}
						rel="noreferrer"
					>
						<b className="text-lg font-semibold">A </b>: via Antonello da Messina 5, MI
					</a>
				</p>
			</div>
			<div className=" md:col-start-10 md:col-span-3 col-span-12 col-start-2">
				
			</div>
			<div className=" text-black cursor-pointer opacity-50 md:col-start-2 md:mt-0 md:col-span-2 col-span-12 mt-5 text-center">
				<a href="https://webinal.it" target="_blank" rel="noreferrer">
					2023 © Webinal
				</a>
			</div>
		</div>
			)
		}
		</>
	);
}

export default Footer;
